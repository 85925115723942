import React, { useEffect, useState, useCallback } from 'react';
import '../styles/global/modal.css';

const Modal = ({ showModal, setShowModal, title, children }) => {
    const [visible, setVisible] = useState(false);

    const handleClose = useCallback(() => {
        setVisible(false);
        setTimeout(() => {
            setShowModal(false);
            document.body.style.overflow = ''; // Re-enable scrolling
        }, 300); // Match this with your CSS transition duration
    }, [setShowModal]);

    useEffect(() => {
        if (showModal) {
            setVisible(true);
            document.body.style.overflow = 'hidden';
        }
    }, [showModal]);

    useEffect(() => {
        const closeOnEscape = (e) => {
            if (e.key === "Escape") {
                handleClose();
            }
        };

        window.addEventListener("keydown", closeOnEscape);
        return () => {
            window.removeEventListener("keydown", closeOnEscape);
        };
    }, [handleClose]);

    const overlayClass = visible ? 'fade-in' : 'fade-out';
    const contentClass = visible ? 'fade-in' : 'fade-out';

    return (
        <div className={`modal-overlay ${overlayClass}`} onClick={handleClose}>
            <div className={`modal-content ${contentClass}`} onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h3>{title}</h3>
                    <button className="modal-close-button" onClick={handleClose}>×</button>
                </div>
                <div className="modal-content-body">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
