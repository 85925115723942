import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage.js';
import Main from './pages/Main.js';
import TaskPage from './pages/TaskPage.js';
// import ChatPage from './pages/ChatPage.js';

const App = () => {
  const userInfo = useSelector(state => state.auth.userInfo);

  return (
    <Router>
      <div className="app">
        {userInfo ? (
          <Routes>
            <Route path="/" element={<Main />} />
            {/* <Route path="/chatbot" element={<ChatPage />} /> */}
            <Route path="/:frameworkName" element={<Main />} />
            <Route path="/:framework/:taskIndex" element={<TaskPage />} />
          </Routes>
        ) : (
          <LoginPage />
        )}
        <ToastContainer />
      </div>
    </Router>
  );
};

export default App;
