import React from 'react';
import '../styles/components/Switch.css';

const Switch = ({ isChecked, onChange, label }) => {
  return (
    <div className="switch-container">
      {label && <label className="switch-label">{label}</label>}
      <div className="switch">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          className="switch-input"
          id="switch"
        />
        <label className="switch-slider" htmlFor="switch">
          <span className="switch-label-on">On</span>
          <span className="switch-label-off">Off</span>
        </label>
      </div>
    </div>
  );
};

export default Switch;
