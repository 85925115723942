import React from 'react';
import { useSelector } from 'react-redux';
import Modal from './Modal'; // Import the reusable Modal component
import '../styles/modals/CynomiTaskModal.css';

const CynomiModal = ({ showModal, setShowModal, UID }) => {
    const cynomiCsvData = useSelector(state => state.data.cynomiCsvData);

    const matchingData = cynomiCsvData.find(item => item.ID === UID);

    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title={`UID: ${UID}`}>
            {matchingData ? (
                <div className="modal-content-values">
                    <div className='left'>
                        {Object.entries(matchingData).filter((_, index) => index % 2 === 0).map(([key, value], index) => {
                            if (value && value.trim().length > 0) {
                                return <p key={index}><strong>{key}:</strong> {value}</p>
                            }
                            return null;
                        })}
                    </div>
                    <div className='right'>
                        {Object.entries(matchingData).filter((_, index) => index % 2 !== 0).map(([key, value], index) => {
                            if (value && value.trim().length > 0) {
                                return <p key={index}><strong>{key}:</strong> {value}</p>
                            }
                            return null;
                        })}
                    </div>
                </div>
            ) : (
                <p>No data found for UID: {UID}</p>
            )}
        </Modal>
    );
};

export default CynomiModal;
