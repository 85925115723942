import { DATA_FILES_URL } from "../constants.js";
import { apiSlice } from "./apiSlice.js";

export const dataFilesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getDataFiles: builder.query({
            query: () => ({
                url: DATA_FILES_URL,
                credentials: 'include'
            }),
            providesTags: ['Data'],
            keepUnusedDataFor: 5
        }),
        getDataFilesNames: builder.query({
            query: () => ({
                url: `${DATA_FILES_URL}/getAllNames`,
                credentials: 'include'
            }),
            providesTags: ['Data'],
            keepUnusedDataFor: 5
        }),
        getDataFileByName: builder.query({
            query: (name) => ({
                url: `${DATA_FILES_URL}/${name}`,
                credentials: 'include'
            }),
            providesTags: ['Data'],
            keepUnusedDataFor: 5
        }),
        updateDataFile: builder.mutation({
            query: (data) => ({
                url: `${DATA_FILES_URL}/${data.name}`,
                method: 'PUT',
                body: {
                    index: data.index,
                    newData: data.newData
                },
                credentials: 'include'
            }),
            invalidatesTags: ['Data']
        }),
        updateDataFileName: builder.mutation({
            query: (data) => ({
                url: `${DATA_FILES_URL}/${data.name}/name`,
                method: 'PUT',
                body: data,
                credentials: 'include'
            }),
            invalidatesTags: ['Data']
        }),
        createDataFile: builder.mutation({
            query: (data) => ({
                url: `${DATA_FILES_URL}`,
                method: 'POST',
                body: data,
                credentials: 'include'
            }),
            invalidatesTags: ['Data']
        }),
        getCynomiCsv: builder.query({
            query: () => ({
                url: `${DATA_FILES_URL}/getCynomiDataFile`,
                credentials: 'include'
            }),
            providesTags: ['Data'],
            keepUnusedDataFor: 5
        }),
        deleteDataFile: builder.mutation({
            query: (name, username) => ({
                url: `${DATA_FILES_URL}/${name}`,
                method: 'DELETE',
                body: { username },
                credentials: 'include'
            }),
            invalidatesTags: ['Data']
        }),
        reuploadDataFile: builder.mutation({
            query: ({name, newName, selectedFilters, colsToKeep}) => ({
                url: `${DATA_FILES_URL}/reupload/${name}`,
                method: 'POST',
                body: {newName, selectedFilters, columnsToKeep: colsToKeep},
                credentials: 'include'
            }),
            invalidatesTags: ['Data']
        }),
        matchNextFiveTasksDataFile: builder.mutation({
            query: ({name, index, policies=null}) => ({
                url: `${DATA_FILES_URL}/${name}/${index}/match`,
                method: 'POST',
                credentials: 'include',
                body: { policies }
            }),
            invalidatesTags: ['Data']
        }),
        updateDataFileStats: builder.mutation({
            query: ({ name }) => ({
                url: `${DATA_FILES_URL}/${name}/stats`,
                method: 'PUT',
                credentials: 'include',
            }),
            invalidatesTags: ['Data']
        }),
    }),
})

export const { 
    useGetDataFilesQuery, 
    useGetDataFileByNameQuery, 
    useUpdateDataFileMutation, 
    useGetDataFilesNamesQuery, 
    useCreateDataFileMutation, 
    useGetCynomiCsvQuery,
    useDeleteDataFileMutation,
    useUpdateDataFileNameMutation,
    useReuploadDataFileMutation,
    useMatchNextFiveTasksDataFileMutation,
    useUpdateDataFileStatsMutation
} = dataFilesApiSlice;
