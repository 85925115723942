import React, { useEffect, useState } from "react";
import DataTable from "../components/DataTable";
import { toast } from "react-toastify";
import { useCreateDataFileMutation } from "../slices/dataFilesApiSlice";
import Dropdown from "../components/Dropdown";
import { setNotificationCollapsed, setNotificationShown } from "../slices/uiSlice";
import { setFramework } from "../slices/dataSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal"; 
import Switch from "../components/Switch";

const NewMappingModal = ({ showModal, setShowModal, file }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [controlIndex, setControlIndex] = useState(0);
    const [predefinedTasksIndex, setPredefinedTasksIndex] = useState(-1);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [statsEnabled, setStatsEnabled] = useState(false); 

    const [createDataFile] = useCreateDataFileMutation();

    useEffect(() => {
        if (file.data.length > 0) {
            // Set default filters
            setSelectedFilters(file.data[0].slice(0, file.data[0].indexOf("User Mapping") - 2));

            // Set default control index (first column with unique values)
            const firstUniqueIndex = file.data[0].findIndex((_, i) => {
                const values = file.data.map(row => row[i]);
                return new Set(values).size === values.length; // Check if all items are unique
            });
            if (firstUniqueIndex !== -1) {
                setControlIndex(firstUniqueIndex);
            }
        }
    }, [file]);

    const handleFormSubmit = async () => {
        setShowModal(false);

        const newMappingFilesData = file.data;
        const newMappingControlIndex = controlIndex;

        let fileName = file.name;
        if (fileName.endsWith(".xlsx")) {
            fileName = fileName.slice(0, -5);
        }

        // Send selected filters along with the data
        const output = {
            name: fileName,
            data: newMappingFilesData,
            selectedFilters,
            predefinedTasksIndex,
            controlIndex: newMappingControlIndex,
            stats: statsEnabled
        };

        // Trigger the notification center to show
        dispatch(setNotificationCollapsed(false));
        dispatch(setNotificationShown(true));
        
        const result = await createDataFile(output);
        if (result.error) {
            toast.error(result.error.data.message);
        }

        dispatch(setFramework(file.name));

        // Close the notification center
        dispatch(setNotificationCollapsed(true));
        setTimeout(() => {
            dispatch(setNotificationShown(false));
        }, 500);
        navigate(`/${fileName}`);
    };

    const handleSelectChange = (selectedOption) => {
      setSelectedFilters(selectedOption);
    };

    const handleControlChange = (selectedOptions) => {
        const newControlIndex = file.data[0].indexOf(selectedOptions[0]);
        const controlValues = file.data.map(item => item[newControlIndex]);

        // Create a set to track unique values
        const uniqueValues = new Set();

        // Check for duplicates
        for (const value of controlValues) {
            if (uniqueValues.has(value)) {
                toast.warn("Duplicate value found in control column. Please choose another column.");
                return;  // Exit the function
            }
            uniqueValues.add(value);
        }

        // Set the control index if no duplicates found
        setControlIndex(newControlIndex);
    };

    const handlePredefinedTasksChange = (selectedOptions) => {
        setPredefinedTasksIndex(file.data[0].indexOf(selectedOptions[0]));
        if (selectedOptions[0] === 'No Predefined Tasks') {
            setStatsEnabled(false);
        }
    };

    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title={`Add A New Mapping: "${file.name}"`}>
            <label className="table-label">Showing "{file.name}":</label>
            <div className="data-table-container">
                <DataTable data={file.data} handleRowClick={() => {}} />
            </div>
            <div className="form-container">
                <div className="form-grid">
                    <div className="select-cell">
                        <div className="select-wrapper">
                            <label>Match Mapping By</label>
                            <Dropdown 
                                up 
                                title={''} 
                                options={file.data[0]} 
                                multiple 
                                value={selectedFilters} 
                                onChange={handleSelectChange} 
                            />
                        </div>
                    </div>
                    <div className="select-cell">
                        <div className="select-wrapper">
                            <label>Control/ID of Task is in</label>
                            <Dropdown 
                                up 
                                title={''} 
                                options={file.data[0]} 
                                multiple={false} 
                                value={[file.data[0][controlIndex]]} 
                                onChange={handleControlChange} 
                            />
                        </div>
                    </div>
                    <div className="select-cell">
                        <div className="select-wrapper">
                            <label>Predefined Tasks Matches are in</label>
                            <Dropdown 
                                up 
                                title={''} 
                                options={["No Predefined Tasks", ...file.data[0]]} 
                                multiple={false} 
                                value={[predefinedTasksIndex === -1 ? "No Predefined Tasks" : file.data[0][predefinedTasksIndex]]} 
                                onChange={handlePredefinedTasksChange} 
                            />
                        </div>
                    </div>
                    {predefinedTasksIndex !== -1 && ( // Conditionally render the switch
                        <div className="select-cell">
                            <div className="select-wrapper">
                                <Switch isChecked={statsEnabled} onChange={() => setStatsEnabled(!statsEnabled)} label="Enable Statistics" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <button className="form-submit-button" onClick={handleFormSubmit}>
                SUBMIT
            </button>
        </Modal>
    );
};

export default NewMappingModal;
