import React, { useEffect, useState } from 'react';
import { TbFilePlus } from 'react-icons/tb';
import '../styles/components/FrameworkList.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setFramework, setFrameworksList } from '../slices/dataSlice';
import { useGetDataFilesNamesQuery } from '../slices/dataFilesApiSlice';
import NewMappingModal from '../modals/NewMappingModal';
import { processFile } from '../utils/fileUtils'; 

const FrameworkList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFrameworkModal, setShowFrameworkModal] = useState(false);
    const { data: dataFilesNames, isLoading, error } = useGetDataFilesNamesQuery();
    const chosenFramework = useSelector(state => state.data.framework);
    const frameworkList = useSelector(state => state.data.frameworksList);

    const handleFrameworkClick = (framework) => {
        navigate(`/${framework}`);
        dispatch(setFramework(framework));
    };

    useEffect(() => {
        if (dataFilesNames) {
            const sortedDataFilesNames = [...dataFilesNames].sort((a, b) => a.localeCompare(b));
            dispatch(setFrameworksList(sortedDataFilesNames));
        }
    }, [dataFilesNames, dispatch]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const result = processFile(evt, file);
                setSelectedFile(result);
                setShowFrameworkModal(true);
            };
            reader.readAsArrayBuffer(file);
            e.target.value = null;
        }
    };

    const openFilePicker = () => {
        const dataFileInput = document.getElementById('dataFileInput');
        dataFileInput.click();
    };

    return (
        <>
            <div className="framework-list">
                <div className="framework-list-header">
                    <h3>Frameworks</h3>
                    <button className="icon-button" onClick={openFilePicker}>
                        <TbFilePlus className="plus-icon" size={26} />
                    </button>
                    <input
                        id="dataFileInput"
                        type="file"
                        accept=".xlsx, .xls"
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e, 'framework')}
                    />
                </div>
                {isLoading || !frameworkList ? (
                    <ul>
                        <li>Loading...</li>
                    </ul>
                ) : error ? (
                    <div>{error?.data?.message || error.error}</div>
                ) : (
                    <div className='framework-list-body'>
                        <ul>
                            {frameworkList.map((name) => (
                                <li key={name}>
                                    <button className={`framework-button ${chosenFramework === name && 'active'}`} onClick={() => handleFrameworkClick(name)}>
                                        {name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            {showFrameworkModal && <NewMappingModal showModal={showFrameworkModal} setShowModal={setShowFrameworkModal} file={selectedFile}/>}
        </>
    );
};

export default FrameworkList;
