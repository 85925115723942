import React, { useState, useRef } from 'react';
import Dropdown from './Dropdown';
import '../styles/components/DescriptionTask.css';
import { useGetTaskFromDescMutation } from '../slices/featuresApiSlice';
import { useGetAllPoliciesQuery } from '../slices/featuresApiSlice';
import HashLoader from "react-spinners/HashLoader"; 
import DataTable from './DataTable';
import { MdArrowBack, MdExpandLess, MdExpandMore } from 'react-icons/md';
import CynomiModal from '../modals/CynomiTaskModal';

const DescriptionTaskMatch = ({ isCollapsed, setIsCollapsed }) => {
  const [inputDescription, setInputDescription] = useState('');
  const [policies, setPolicies] = useState([]);
  const [matchData, setMatchData] = useState([]);
  const descriptionMatchContainerRef = useRef(null);
  const [mode, setMode] = useState('input');
  const [showModal, setShowModal] = useState(false);
  const [selectedUID, setSelectedUID] = useState('');
  
  const [ getTaskFromDesc ] = useGetTaskFromDescMutation();
  const { data: policiesData } = useGetAllPoliciesQuery();

  const handleRowClick = (row) => {
    setSelectedUID(row[0]);
    setShowModal(true);
  }

  const handleDescriptionMatching = async (e) => {
    e.preventDefault();
    
    setMode('loading');
    const data = {
      text: inputDescription,
      count: 60,
      minScore: 0.3,
      policies
    }
    const response = await getTaskFromDesc(data);
    
    setMode('data');
    if (response.error) {
      setMatchData([]);
      return;
    } else if (!response?.data?.matches && response.data.length === 0) {
      setMatchData([['No Matches Found']]);
      return;
    } 
    const transformedData = [['Task UID', 'Task Name', 'Policy', 'Match Score', 'Reason for Match'], ...response.data.matches.map(item => [item['task_id'], item['task_name'], item['task_policy'], item['match_score'], item['reason_to_match']])];
    setMatchData(transformedData);
  }

  const handleReturnToInputMode = () => {
    setMatchData([]);
    setMode('input');
  }

  return (
    <div className={`description-match-container ${isCollapsed ? 'collapsed' : ''}`} ref={descriptionMatchContainerRef}>
      <div className="description-match-header">
        <span className="description-match-title">Description Matching {mode === 'data' && ` - ${inputDescription.split(' ').length > 20 ? inputDescription.split(' ').slice(0, 20).join(' ') + '...' : inputDescription}`}</span>
        {mode === 'data' && <button className="return-button" onClick={handleReturnToInputMode}><MdArrowBack size={24} /></button>}
        <button className="collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
          {isCollapsed ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
        </button>
      </div>
      {!isCollapsed && (
        <div className="description-match-content">
          {mode === 'loading' ? (
            <div className="overlay"> 
              <HashLoader size={100} color={"#8D8D8D"} />
            </div>
          ) : mode === 'data' ? (
            <DataTable data={matchData} handleRowClick={handleRowClick} filtersDisabled />
          ) : null}
        </div>
      )}
      {!isCollapsed && mode === 'input' && (
        <form className="description-match-input" onSubmit={handleDescriptionMatching}>
          <input
            type="text"
            value={inputDescription}
            onChange={(e) => setInputDescription(e.target.value)}
            placeholder="Enter Description..."
            id='description_search_text'
          />
          <Dropdown value={policies} onChange={setPolicies} title={"Choose Policies"} options={policiesData?.length > 0 ? policiesData : []} multiple buttonClassName={'mx1 description-match-button'} up/>
          <button className='description-match-button' type="submit" disabled={inputDescription?.trim().length < 1 || policies.length === 0}>Send</button>
        </form>
      )}
      {showModal && <CynomiModal showModal={showModal} setShowModal={setShowModal} UID={selectedUID}/>}
    </div>
  );
};

export default DescriptionTaskMatch;
