import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar.js';
import FrameworkList from '../components/FrameworkList.js';
import FrameworkTable from '../components/FrameworkTable.js';
import DescriptionTaskMatch from '../components/DescriptionTask.js';
import NotificationCenter from '../components/NotificationCenter.js';
import { TbFileOff } from 'react-icons/tb';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetCynomiCsvQuery, useGetDataFileByNameQuery } from '../slices/dataFilesApiSlice.js';
import { setFramework, setCynomiCsvData, setFrameworkData } from '../slices/dataSlice.js';
import { setTaskMatchCollapsed, setNotificationCollapsed, setNotificationShown } from '../slices/uiSlice.js';
import '../styles/global/colors.css';
import '../styles/pages/Main.css';

const Main = () => {
  const { frameworkName: frameworkParam } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isTaskMatchCollapsed = useSelector((state) => state.ui.isTaskMatchCollapsed);
  const isNotificationCollapsed = useSelector((state) => state.ui.isNotificationCollapsed);
  const isNotificationShown = useSelector((state) => state.ui.isNotificationShown);

  const framework = useSelector((state) => state.data.framework);

  const [errorMessage, setErrorMessage] = useState(null); // State to hold error message

  // Fetch Cynomi CSV data
  const {
    data: cynomiData,
    isLoading: isCynomiLoading,
    error: cynomiError,
  } = useGetCynomiCsvQuery();

  // Fetch framework data
  const {
    data: fetchedFrameworkData,
    isLoading: isFrameworkLoading,
    error: frameworkError,
  } = useGetDataFileByNameQuery(framework, {
    skip: !framework, // Skip fetching if framework is not set
  });

  useEffect(() => {
    if (frameworkParam) {
      dispatch(setFramework(decodeURIComponent(frameworkParam)));
    } else {
      dispatch(setFramework(''));
    }
  }, [frameworkParam, dispatch]);

  // Save Cynomi CSV data to Redux store
  useEffect(() => {
    if (cynomiData && !isCynomiLoading && !cynomiError) {
      dispatch(setCynomiCsvData(cynomiData));
    } else if (cynomiError) {
      setErrorMessage('Error fetching Cynomi CSV data.');
      navigate('/'); // Redirect to home page if an error occurs
    }
  }, [cynomiData, isCynomiLoading, cynomiError, dispatch, navigate]);

  // Save framework data to Redux store
  useEffect(() => {
    if (fetchedFrameworkData && !isFrameworkLoading && !frameworkError) {
      dispatch(setFrameworkData(fetchedFrameworkData));
      setErrorMessage(null); // Clear error message
    } else if (frameworkError) {
      if (frameworkError.status === 404) {
        setErrorMessage('404 - Framework not found.');
      } else {
        setErrorMessage('Error fetching framework data.');
      }
      navigate('/'); // Redirect to home page if an error occurs
    }
  }, [fetchedFrameworkData, isFrameworkLoading, frameworkError, dispatch, navigate]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--description-match-height',
      isTaskMatchCollapsed ? '5%' : '25%'
    );
  }, [isTaskMatchCollapsed]);

  const toggleNotificationCenter = () => {
    dispatch(setNotificationCollapsed(!isNotificationCollapsed));
    if (!isNotificationShown) {
      dispatch(setNotificationShown(true));
    } else {
      setTimeout(() => {
        dispatch(setNotificationShown(false));
      }, 500);
    }
  };

  return (
    <div className="main-container">
      <Navbar
        pageInfo={framework ? `Viewing ${framework}` : null}
        homeLink=""
      />
      <div className="content-container">
        <div className="frameworks-container">
          <FrameworkList />
        </div>
        <div className="table-container">
          {errorMessage ? ( // If there's an error, show a nice message
            <div className="error-container">
              <TbFileOff size={80} color="var(--secondary-color)" />
              <h1>Oops! Something went wrong</h1>
              <p>{errorMessage}</p>
            </div>
          ) : (
            <FrameworkTable />
          )}
        </div>
        {isNotificationShown && (
          <div className="notification-container">
            <NotificationCenter
              isNotificationCollapsed={isNotificationCollapsed}
              toggleCollapse={toggleNotificationCenter}
            />
          </div>
        )}
      </div>
      <DescriptionTaskMatch
        isCollapsed={isTaskMatchCollapsed}
        setIsCollapsed={(collapsed) => dispatch(setTaskMatchCollapsed(collapsed))}
      />
    </div>
  );
};

export default Main;
