export const basePolicies = {name: 'Choose All Basic Policies', items: [
    'Access',
    'Awareness',
    'Business continuity',
    'Data protection',
    'Email and Messages',
    'Endpoints and Mobile',
    'Human Resources',
    'Incident Response',
    'Information Security Management',
    'Logging and Monitoring',
    'Passwords and Secrets',
    'Service Provider and Vendor Management',
    'Vulnerability Management',
    'Asset Management',
    'Change and Configuration Management',
    'Compliance and Auditing',
    'Operational and Maintenance',
    'Privacy',
    'Risk management',
    'Threat Intelligence',
]}

export const envPolicies = {name: 'Choose All Env Policies', items: [
    'Active Directory',
    'Cloud Security',
    'Domain and DNS',
    'Hosted Network',
    'Hosted Server',
    'Microsoft 365',
    'On-prem Network',
    'On-prem Server',
    'Remote Access',
    'Software Development',
    'Website and Web Application',
    'Card Payment',
    'Operational Technology (OT) Security',
    'Environmental Control',
    'Physical Infrastructure',
]}