import React, { useEffect, useState } from 'react';
import { TbEdit, TbTrash, TbDownload, TbRefresh } from 'react-icons/tb';
import '../styles/components/ManageFrameworkOption.css';
import { useDeleteDataFileMutation, useGetDataFileByNameQuery, useReuploadDataFileMutation, useUpdateDataFileNameMutation } from '../slices/dataFilesApiSlice';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setFramework } from '../slices/dataSlice';
import { setNotificationShown, setNotificationCollapsed } from '../slices/uiSlice';
import { utils, write } from 'xlsx';
import Modal from '../modals/Modal';  // Import the Modal component
import DataTable from './DataTable';
import Dropdown from './Dropdown';

const saveArrayToExcel = (array, name) => {
    const ws = utils.aoa_to_sheet(array);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');
    const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${name}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

const ManageFrameworkOption = ({ text }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [deleteDataFile] = useDeleteDataFileMutation();
    const [updateDataFileName] = useUpdateDataFileNameMutation();
    const [reuploadDataFile] = useReuploadDataFileMutation();
    const { data: excelData, isLoading } = useGetDataFileByNameQuery(text);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showReuploadModal, setShowReuploadModal] = useState(false);
    const [newFrameworkName, setNewFrameworkName] = useState(text);
    const [newReuploadName, setNewReuploadName] = useState(`${text}_reupload`);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [colsToKeep, setColsToKeep] = useState([]);

    useEffect(() => {
        if (excelData && !isLoading) {
            setColsToKeep([...excelData.data[0].slice(0, excelData.userMappingIndex - 2), excelData.data[0][excelData.userMappingIndex ]]);
            setSelectedFilters(excelData.data[0].slice(0, excelData.userMappingIndex - 2));
        }
    }, [excelData, isLoading]);

    const handleDeleteClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowConfirmModal(true);
    };

    const handleDownloadClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (excelData && !isLoading) {
            await saveArrayToExcel(excelData.data, text);
        }
    };

    const handleEditClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowEditModal(true);
    };

    const handleRematchClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowReuploadModal(true);
    };
    
    const handleConfirmedEdit = async () => {
        try {
            await updateDataFileName({ name: text, newName: newFrameworkName });
            dispatch(setFramework(newFrameworkName));
            toast.success(`${text} framework renamed to ${newFrameworkName}`);
            navigate(`/${newFrameworkName}`);
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
        setShowEditModal(false);
    };

    const handleConfirmedDelete = async () => {
        try {
            await deleteDataFile(text, JSON.parse(localStorage.getItem("userInfo"))?.username);
            dispatch(setFramework(null));
            navigate('/');
            toast.success(`${text} framework deleted successfully`);
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
        setShowConfirmModal(false);
    };

    const handleReupload = async () => {
        try {
            setShowReuploadModal(false);
        
            // Trigger the notification center to show
            dispatch(setNotificationCollapsed(false));
            dispatch(setNotificationShown(true));
            
            const result = await reuploadDataFile({ name: text, newName: newReuploadName, selectedFilters, colsToKeep });
            if (result.error) {
                toast.error(result.error.data.message);
            }

            dispatch(setFramework(newReuploadName));

            // Close the notification center
            dispatch(setNotificationCollapsed(true));
            setTimeout(() => {
                dispatch(setNotificationShown(false));
            }, 500);
            navigate(`/${newReuploadName}`);
        } catch (error) {
            toast.error(error?.data?.message || error.error);
        }
        setShowReuploadModal(false);
    }

    return (
        <div className="manage-framework-option">
            <div className="icon-buttons">
                <button className="edit-button edit-icon" onClick={handleEditClick}>
                    <TbEdit size={20} />
                </button>
                <button className="download-button save-icon" onClick={handleDownloadClick}>
                    <TbDownload size={20} />
                </button>
                <button className="delete-button delete-icon" onClick={handleDeleteClick}>
                    <TbTrash size={20} />
                </button>
                <button className="rematch-button rematch-icon" onClick={handleRematchClick}>
                    <TbRefresh size={20} />
                </button> 
            </div>
            <div className="text-wrapper">
                <span className="option-text">{text}</span>
            </div>
            
            {showConfirmModal && (
                <Modal showModal={showConfirmModal} setShowModal={setShowConfirmModal} title="Confirm Deletion">
                    <p>Are you sure you want to delete <strong>{text}</strong> framework?</p>
                    <div className="button-container">
                        <button className="form-action-button" onClick={handleConfirmedDelete}>Yes</button>
                        <button className="form-action-button" onClick={() => setShowConfirmModal(false)}>No</button>
                    </div>
                </Modal>
            )}
            
            {showEditModal && (
                <Modal showModal={showEditModal} setShowModal={setShowEditModal} title={`Edit ${text}'s name`}>
                    <div className="input-container">
                        <p className='input-label'>Enter new name:</p>
                        <input
                            className='input-field'
                            type="text"
                            value={newFrameworkName}
                            onChange={e => setNewFrameworkName(e.target.value)}
                        />
                    </div>
                    <div className="button-container">
                        <button className="form-action-button" onClick={handleConfirmedEdit}>Save</button>
                        <button className="form-action-button" onClick={() => setShowEditModal(false)}>Cancel</button>
                    </div>
                </Modal>
            )}

            {showReuploadModal && (
                <Modal showModal={showReuploadModal} setShowModal={setShowReuploadModal} title={`Reupload ${text}`}>
                    {(excelData && !isLoading) && (
                        <div className="data-table-container">
                            <p className="input-label">Showing "{excelData.name}":</p>
                            <DataTable data={excelData.data} handleRowClick={() => {}} />
                        </div>
                    )}
                    <div className="input-container">
                        <p className='input-label'>Enter name for reuploaded file:</p>
                        <input
                            className='input-field'
                            type="text"
                            value={newReuploadName}
                            onChange={e => setNewReuploadName(e.target.value)}
                        />
                    </div>
                    {(excelData && !isLoading) && (
                        <div className='form-grid'>
                            <div className="select-cell">
                                <form>
                                    <div className="select-wrapper">
                                        <p className='input-label'>Match Mapping By:</p>
                                        <Dropdown 
                                            up 
                                            title={selectedFilters.length > 0 ? `${selectedFilters.length} Headers Chosen` : `Choose Headers...`} 
                                            options={excelData.data[0]} 
                                            multiple 
                                            value={selectedFilters} 
                                            onChange={itm => setSelectedFilters(itm)} 
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="select-cell">
                                <form>
                                    <div className="select-wrapper">
                                        <p className='input-label'>Coloumns to keep:</p>
                                        {console.log(colsToKeep, excelData.userMappingIndex)}
                                        <Dropdown 
                                            up 
                                            title={colsToKeep.length > 0 ? `${colsToKeep.length} Headers Chosen` : `Choose Headers...`} 
                                            options={excelData.data[0]} 
                                            multiple 
                                            value={colsToKeep} 
                                            onChange={itm => setColsToKeep(itm)} 
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    <div className="button-container">
                        <button className="form-action-button" onClick={handleReupload}>Reupload</button>
                        <button className="form-action-button" onClick={() => setShowReuploadModal(false)}>Cancel</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default ManageFrameworkOption;
